import React, { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { mainData } from "./NavData";

export const NavUser = () => {
  const [isOpen, setIsOpen] = useState([]);
  const collapseRef = useRef([]);

  const toggleCollapse = (index) => {
    setIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  function shouldRenderNavServicosInternos() {
    const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));
    return userFromLocalStorage?.user_type !== 'profissional';
  }

  const location = useLocation();
  const { pathname, search } = location;

  return (
    <>
      {mainData.map((data, index) => {
        const isCurrentPath = data.href === `../..${pathname}`;
        const isCurrentSubPath = data.subData.href === `../..${pathname}`;
        
        return (
          <div
            className={`
              ${data.topDist}
              ${data.id === "navServicosInternos" && shouldRenderNavServicosInternos() ? "collapse" : ""}
              ${isCurrentPath || isCurrentSubPath ? 'bg-primary text-white w-100' : 'col-md-12'}
              ${isOpen[index] ? 'bg-info' : ''}
            `}
            style={{ padding: '0px', borderRadius: '0.25rem' }}
            key={data.title}
          >
            {data.subData.length === 0 ? (
              <Link
                to={`${data.href}${search}`}
                className={`navbar-brand ${isCurrentPath ? 'font-weight-bold text-white' : 'text-dark'}`}
                style={{ cursor: 'pointer', marginTop: '0px' }}
              >
                <i className={`fa ${data.icon} ml-2`} />
                <span className={`ml-1 ${isCurrentPath ? 'text-white' : 'text-dark'}`}>
                  {data.title}
                  {isOpen[index] ? (
                    <i className="fas fa-chevron-down" style={{ scale: "0.5" }}></i>
                  ) : (
                    data.subData.length === 0 ? (
                      <>
                        {isCurrentPath && <i className="fas fa-circle align-right mt-1" style={{ scale: "0.4" }}></i>}
                        {!isCurrentPath && <i className="fas fa-circle align-right mt-1" style={{ scale: "0.4" }}></i>}
                      </>
                    ) : (
                      <i className="fas fa-chevron-right align-right mt-1" style={{ scale: "0.5" }}></i>
                    )
                  )}
                </span>
              </Link>
            ) : (
              <>
                <div
                  onClick={() => toggleCollapse(index)}
                  className={`navbar-brand ${isOpen[index] ? 'font-weight-bold text-white' : 'text-dark mt-1'} ${data.topDist}`}
                  style={{ padding: '0px', cursor: 'pointer', marginBottom: '0px' }}
                >
                  {data.subData.href}
                  <i className={`fa ${data.icon} text-dark ml-2`} />
                  <span className="ml-1 text-dark">
                    {data.title}
                    {isOpen[index] ? (
                      <i className="fas fa-chevron-down align-right mt-1" style={{ scale: "0.5" }}></i>
                    ) : (
                      <i className="fas fa-chevron-right align-right mt-1" style={{ scale: "0.5" }}></i>
                    )}
                  </span>
                </div>
                <div
                  className={`collapse ${isOpen[index] ? "show" : ""}`}
                  ref={(el) => (collapseRef.current[index] = el)}
                  style={{ padding: '0px', marginBottom: '0px' }}
                >
                  {data.subData.map((subData) => (
                    <li className="text-dark text-left mt-1 ml-3" key={subData.id}>
                      <a href={`${subData.href}${search}`}>
                        <i className={`fa ${subData.icon}`} />
                        <span className="ml-1 text-dark">{subData.title}</span>
                        <span className="badge badge-pill bg-danger text-dark">
                          {subData.count}
                        </span>
                      </a>
                    </li>
                  ))}
                </div>
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

export default NavUser;
