import { Index } from "./Index";
import { FooterProfissional, Nav, NavProfissionalMain } from "../../../../components";
export const Profissionais = () => {
    return (<>
    <Nav/>
        <div className="d-flex mt-3" style={{overflowX:'hidden'}}>
            <NavProfissionalMain />
            <section className="w-100">
                <Index />
                <FooterProfissional />
            </section>
        </div>
    </>)
};