import React, { useState } from "react";

export function IconInfo(props) {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  return (
    <>
      {open && (
        props.text
      )}
      <a href={props.centralC} onClick={toggle}> <i className={`${!open ? 'text-primary' : 'text-danger'} fa fa-info-circle ml-2 `}></i></a>
    </>
  );
}

export default IconInfo;