import { useState, useContext, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { AuthContext } from "../../../../../contexts/auth"
import {
  ButtonAPI,
  InputPadrao,
  InputPadraoTitle,
  InputDataNascimento,
  TermoDeResponsabilidadeCadastro,
  is_cpf,
  mCPF,
  TitleL1,
  TitleL2,
} from '../../../../../components'
import { Divider, Form, Select } from "antd";  // Importando o Select do Ant Design
import PhoneInput from 'react-phone-input-2';
import '../style/style.css';
import TesteAutoCadastrar from "../testes/TesteAutoCadastrar";
import { atualizarCadastroPaciente, fetchPatientData } from "../../../../../services";
import { CadastroAuth } from "./CadastroAuth";
import CadastroAddress from "./CadastroAddress";
import useLocalizacao from "./useLocalizacao";
import { checkPasswordSecurity, generateSecurePassword, handleConfirmPasswordChange, SecurityBars } from "../utils/PasswordChange";
import { handleEmailChange, IsValidEmail } from "../utils/IsValidEmail";
import { validateForm } from "../utils/validateForm";  // Importando a função de validação
const { Option } = Select;  // Extraindo o Option do Select
export const IndexPublicCadastrar = () => {
  const { cadastrar } = useContext(AuthContext)
  const [cadastroNativo, setCadastroNativo] = useState(false);
  const [name, setName] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [phone, setPhone] = useState("");
  const [cpf, setCpf] = useState("");
  const [validationResponse, setValidationResponse] = useState("");
  const { latitude, setLatitude, longitude, setLongitude, address, getLocalizacao } = useLocalizacao();
  const inputRef = useRef(null)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [securityLevel, setSecurityLevel] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [profissional, setProfissional] = useState(false);
  const [user_type, setUser_type] = useState("paciente");
  const { getUserId } = useContext(AuthContext);
  const id = getUserId();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const convite_code = params.get('convite_code');
  const [passwordAtualizado, setPasswordAtualizado] = useState("")
  const [aceitouTermo, setAceitouTermo] = useState(false);
  const onclickCadastroNativo = () => {
    setCadastroNativo((prevCadastroNativo) => !prevCadastroNativo);
    setAceitouTermo(true)
  };
  const [prevCadastroNativo, setPrevCadastroNativo] = useState(false);
  //console.log("Convite Code:", convite_code);  
  const handleProfissionalChange = value => {
    setProfissional(value);
    monitorarCadastroProfissional(value);
  }
  function monitorarCadastroProfissional(value) {
    if (value === 'SIM') {
      localStorage.setItem('cadastroProfissional', 'SIM');
    } else {
      localStorage.removeItem('cadastroProfissional');
    }
  }

  const postCadastrarPaciente = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const formValues = { name, cpf, validationResponse, dataNascimento, email, phone, password, confirmPassword, aceitouTermo };
    const validationResult = validateForm(formValues, setError, IsValidEmail);
    if (validationResult) {
      alert(validationResult.message);
      document.getElementById(validationResult.field)?.focus(); // Focar no campo com erro
      setIsLoading(false);
      return;
    }
    setUser_type(profissional ? 'paciente' : 'paciente');
    // console.log("Enviando dados de cadastro:", {
    //   email,
    //   name,
    //   password,
    //   cpf,
    //   address,
    //   phone,
    //   dataNascimento,
    //   user_type,
    //   convite_code
    // });
    try {
      await cadastrar(email, name, password, cpf, address, phone, dataNascimento, user_type, convite_code);
      alert('Cadastrado com sucesso transferindo para parte interna');
    } catch (error) {
      alert('Erro ao cadastrar: ' + error.response.data.message);
      setIsLoading(false);
    }
  }
  const handlePhoneChange = (value) => {
    setPhone(value);
  };
  const isCadastrarPage = location.pathname.includes("/cadastrar");

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchPatientData(id, isCadastrarPage, setName, setCpf, setDataNascimento, setEmail, setLatitude, setLongitude, setPhone);
      } catch (error) {
        alert(error.message); // Handle errors appropriately
      }
    };
    fetchData();
  }, [setLatitude, setLongitude, isCadastrarPage, id]);

  useEffect(() => {
    if (!prevCadastroNativo && cadastroNativo) {
      setTimeout(() => {
        window.scrollBy({ top: 600, behavior: "smooth" });
      }, 500);
    }
    setPrevCadastroNativo(cadastroNativo);
  }, [cadastroNativo, prevCadastroNativo]);
  return (<>
    <div id="cadastrar" className={`${isCadastrarPage ? 'py-5 ' : ''}`} style={{ minHeight: isCadastrarPage ? "85vh" : '', overflow: 'hidden' }} >
      {isCadastrarPage && <>
        <div className='text-center'>
          <i className="border" style={{ borderRadius: '50%', borderColor: '#000080', border: '5px' }}>  <i className='fa  fa-user-plus fa-5x py-2  text-primary mx-auto mb-3 mt-5 '
          >  </i></i>
        </div>
      </>}
      {isCadastrarPage && aceitouTermo && <> <CadastroAuth onclickCadastroNativo={onclickCadastroNativo} /></>}
      {(!isCadastrarPage || cadastroNativo) && <>
        <div className='text-center'>
          <TesteAutoCadastrar
            setName={setName}
            setEmail={setEmail}
            setCpf={setCpf}
            setDataNascimento={setDataNascimento}
            setPhone={setPhone}
            setPassword={setPassword}
            setConfirmPassword={setConfirmPassword}
            setShowPassword={setShowPassword}
            setProfissional={setProfissional}
            getLocalizacao={getLocalizacao}
          />
        </div>
        <Form
          className='border bg-light py-2 rounded'
          initialValues={{ remember: true }}
          layout="vertical"
          name="cadastrar"
          onSubmit={postCadastrarPaciente}>   <TitleL2>Dados do Usuário</TitleL2>
          <Divider />
          <div className="row col-md-12 justify-content-center">
            <div className=" col-md-5">
              <Form.Item
                name="name"
                rules={[{ required: true, message: 'Por favor, insira um nome!' }]}>
                <InputPadrao
                  title={'Nome'}
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Nome"
                  id="name"
                  name="name"
                  required
                />
              </Form.Item>
            </div>
            <div className="form-group col-md-5 ">
              <InputPadrao
                title={'Identificação Única'}
                type="text"
                value={cpf}
                maxLength="14"
                onChange={(e) => {
                  const formattedCpf = mCPF(e.target.value);
                  setCpf(formattedCpf);
                  const isCpfValid = is_cpf(e.target.value);
                  setValidationResponse(isCpfValid ? 'VÁLIDO' : 'ATENÇÃO: Informe um CPF válido. Insira apenas números');
                }}
                name="cpf"
                placeholder="Brasil: CPF"
                id="cpf"
                required
              />
              <span id="cpfResponse">{validationResponse}</span>
            </div>
            <div className="form-group col-md-5 ">
              <InputDataNascimento dataNascimento={dataNascimento} setDataNascimento={setDataNascimento} />
            </div>
            <div className="form-group col-md-5 ">
              <InputPadraoTitle title={'Telefone'} />
              <PhoneInput
                country={'br'}
                placeholder="Número de telefone"
                value={phone}
                required
                onChange={handlePhoneChange}
              />
            </div>
            <div className="form-group col-md-5 ">
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Por favor, insira um e-mail!' },
                  { type: 'email', message: 'O e-mail informado não é válido!' }
                ]}>
                <InputPadrao
                  title={'E-mail'}
                  type="email"
                  value={email}
                  style={{ borderRadius: ' 2px 2px 2px 2px' }}
                  name="email"
                  placeholder="E-MAIL"
                  id="email"
                  onChange={(event) => handleEmailChange(event, setEmail, setError)} // Usa a função exportada
                />  {error && <> {error}</>}
              </Form.Item>
            </div>
            <CadastroAddress latitude={latitude} setLatitude={setLatitude}
              longitude={longitude} setLongitude={setLongitude} getLocalizacao={getLocalizacao} />
            <div className="form-group col-md-5">
              <InputPadrao
                title={isCadastrarPage ? 'SENHA' : 'SENHA ANTIGA'}
                type={showPassword ? "text" : "password"}
                value={password}
                required
                onChange={(e) => {
                  setPassword(e.target.value);
                  setSecurityLevel(checkPasswordSecurity(e.target.value));
                  handleConfirmPasswordChange(e, setConfirmPassword)
                }}
                name="password"
                placeholder="********"
                id="password" />
            </div>
            {isCadastrarPage ? (
              <> <div className="form-group col-md-5 text-center">
                <InputPadrao
                  title={'CONFIRMA SENHA'}
                  id="confirmPassword"
                  value={confirmPassword}
                  ref={inputRef}
                  onChange={(e) => handleConfirmPasswordChange(e, setConfirmPassword)}
                  required
                  type={showPassword ? "text" : "password"}
                  placeholder="********"
                  data-bouncer-message="ATENÇÃO: Repita à senha válida."
                />
                <div className="justify-content-center col-md-12 align-items-center mx-auto">
                  <SecurityBars securityLevel={securityLevel} />
                  <div className="form-group col-md-5 mt-1 d-flex mx-auto justify-content-center">
                    <i className={`fa text-center fa-${showPassword ? 'eye' : 'eye-slash'} `}
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? "Ocultar senha" : "Exibir senha"} </i>
                  </div> </div>
                <button className='btn btn-primary mt-2'
                  type="button"
                  onClick={() => generateSecurePassword(setPassword, setConfirmPassword, setSecurityLevel)}>
                  Gerar senha segura<i className='fa fa-lock ml-2'></i>
                </button>
                <br />
              </div>
              </>) : (<>
                <div className="form-group col-md-5">
                  <div className="input-group">
                    <InputPadrao
                      title={'Senha Nova'}
                      className="form-control text-center col-md-12 border border-dark"
                      type={showPassword ? "text" : "password"}
                      value={passwordAtualizado}
                      required
                      onChange={(e) => {
                        setPasswordAtualizado(e.target.value);
                      }}
                      name="passwordAtualizado"
                      placeholder="********"
                      id="passwordAtualizado"
                    />
                    <div className="input-group-append">
                    </div>
                  </div>
                </div>
              </>)}
            {isCadastrarPage && <>    <div className="col-md-12">
              <TitleL2>Progressão</TitleL2>
              <Divider /></div>
              <div className="form-group col-md-5">
                <InputPadraoTitle title={'Cadastro Profissional'} />
                <Select
                  className="w-100  text-center"
                  value={profissional}
                  onChange={handleProfissionalChange}
                  id="collapseCadastroProfissional"
                >
                  <Option value="NÃO">NÃO</Option>
                  <Option value="SIM">SIM</Option>
                </Select>
              </div>
            </>}
          </div>
          <div className="col-md-10 mx-auto">
            {isCadastrarPage ? (
              <Form.Item>
                <ButtonAPI htmlType="submit" isLoading={isLoading} onClick={postCadastrarPaciente}>Cadastrar</ButtonAPI>
              </Form.Item>
            ) : (
              <ButtonAPI isLoading={isLoading} onClick={() => atualizarCadastroPaciente(
                id,
                email,
                name,
                password,
                passwordAtualizado,
                cpf,
                phone,
                dataNascimento,
                address,
                setIsLoading)}>
                Atualizar
              </ButtonAPI>
            )}
          </div>
        </Form >
      </>}
      {isCadastrarPage && !aceitouTermo && <TitleL1>Cadastrar</TitleL1>}
      <TermoDeResponsabilidadeCadastro aceitouTermo={aceitouTermo} setAceitouTermo={setAceitouTermo} />
    </div >
  </>)
}