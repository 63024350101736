import React, { useState } from "react";
export function MaisInfoMenosInfo(props) {
  const [openMaisInfoMenosInfo, setOpenMaisInfoMenosInfo] = useState(false);
  const MostrarMaisInfo = () => {
    setOpenMaisInfoMenosInfo(!openMaisInfoMenosInfo);
  };
  return (<>
    <div className='text-center font-weight-bold ml-2 mr-2 py-3 mt-2'>
      {!openMaisInfoMenosInfo && <a
        className="text-primary"
        href={props.centralC}
        onClick={MostrarMaisInfo}>
        {openMaisInfoMenosInfo ? 'Menos info ' : 'Mais info '}
        <i className="fa fa-info-circle"></i>
      </a>}
      {openMaisInfoMenosInfo && <a
        className={`text-${openMaisInfoMenosInfo ? 'danger' : 'primary'}`}
        href={props.centralC}
        onClick={MostrarMaisInfo}>
        {openMaisInfoMenosInfo ? 'Menos info ' : 'Mais info '}
        <i className="fa fa-info-circle"></i>
      </a>}
      {openMaisInfoMenosInfo && (
        <div className='text-justify mt-2'>
          <div id={props.centralC}>
            {props.text}
          </div>
          <div className="text-center mt-5">
            <a
              className={`text-${openMaisInfoMenosInfo ? 'danger' : 'primary'}`}
              href={props.centralC}
              onClick={MostrarMaisInfo}>
              {openMaisInfoMenosInfo ? 'Menos info ' : 'Mais info '}
              <i className="fa fa-info-circle"></i>
            </a>
          </div>
        </div>
      )}
    </div>
  </>);
}
export default MaisInfoMenosInfo;