import React, { useState } from 'react';
import { ReferenciaLocal, ButtonAPI, InputPadrao } from "../../../../../../../components"
import { Apresentacao } from "./Apresentacao"
import EutanasiaCalculator from './EutanasiaCalculator';
import axios from 'axios';
import { baseURL } from '../../../../../../../services/api';
import CadastrarProfissionalUnit from '../../../../../Usuarios/Cadastro/components/CadastroEmpresa/layout/CadastrarProfissionalUnit';
import ProfissionalVinculado from '../../../../../Usuarios/Cadastro/components/CadastroProfissional/components/layout/ProfissionalVinculado'
export const BioterioFortePesquisas = () => {
    const [titulo, setTitulo] = useState('');
    const [resumo, setResumo] = useState('');
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'TITULO') {
            setTitulo(value);
        } else if (name === 'RESUMO') {
            setResumo(value);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Verifica se os campos estão preenchidos antes de enviar
        if (!titulo || !resumo) {
            alert('Por favor, preencha todos os campos antes de enviar.');
            return;
        }
        // Construa o objeto com os dados a serem enviados para o backend
        const dadosPesquisa = {
            titulo,
            resumo,
        };

        // Faça a requisição POST para o backend
        axios
            .post(`${baseURL}salvarPesquisa`, dadosPesquisa)
            .then((response) => {
                alert(JSON.stringify(response.data)); // Exiba a mensagem de sucesso no console ou faça algo com a resposta
                // Lide com a resposta do backend aqui, se necessário
            })
            .catch((error) => {
                alert('Erro na requisição POST: ', error);
                // Lide com o erro aqui, se necessário
            });
    };
    return (<>
        <Apresentacao />
        <ReferenciaLocal text={'Gerenciamento Pesquisas'} />
        <div className="text-center d-flex justify-content-center mt-5 col-lg-12 boxFooter bg-primary TridimencionalSupLat">
            <h5 className="text-white mt-2 font-weight-bold">
                <b>TÍTULO DO ESTUDO</b>
            </h5>
        </div>
        <textarea
            required
            id="tituloEstudo"
            type="text"
            rows="2"
            data-ls-module="charCounter"
            name="TITULO"
            maxLength="100"
            className="form-control text-center form-control-lg border-dark"
            value={titulo}
            onChange={handleInputChange}
        ></textarea>
        <small className="text-justify font-weight-bold">Insira o nome da pesquisa. </small>
        <div className="text-center d-flex justify-content-center mt-5 col-lg-12 boxFooter bg-primary TridimencionalSupLat">
            <h5 className="text-white mt-2 font-weight-bold">
                <b>RESUMO DO ESTUDO</b>
            </h5>
        </div>
        <textarea
            id="resumoEstudo"
            type="text"
            rows="10"
            data-ls-module="charCounter"
            maxLength="2000"
            className="form-control text-center form-control-lg border-dark"
            name="RESUMO"
            value={resumo}
            required
            onChange={handleInputChange}
        ></textarea>
        <small className="text-justify font-weight-bold">Inserir o resumo da pesquisa. </small>
        <ButtonAPI onClick={handleSubmit}>Salvar Título Resumo</ButtonAPI>
        <CadastrarProfissionalUnit title={'Pesquisadores'} />
        <ProfissionalVinculado title={'Empresas e/ou Instituições'} />
        <small className="text-justify font-weight-bold"> Clique nos botões dos card e escolha a
            opções. Unidades Preceptoras onde vai acontecer a pesquisa,
            Unidades colaboradoras amparo a pesquisa.</small>
        <small className="text-justify font-weight-bold">Clique em LISTA escolher a
            opção. Fuso horário do local do estudo. </small>
        <small className="text-justify font-weight-bold">Biotério de pesquisa da unidade preceptora selecionado para
            pesquisa em questão. Clique na lista para escolher a opção.</small>
        <h5 className="col-md-12 text-justify">Cada equipamento condiz com a criação de um grupo de
            pesquisa. G1 - Grupo Controle; G2 - Grupo Controle Teste; G3 - Grupo Teste.
        </h5>
        <InputPadrao title='OI' />
        <EutanasiaCalculator />
    </>)

}