import React from 'react';


export const TeamListInfo = () => {

    return (
     
        <div className="text-justify">
        <h5 className="font-weight-bold mt-5">⚛ Fundadores de Projetos</h5>
            <p className='mt-5'>Os "Fundadores de Projetos" são indivíduos visionários que desempenham um papel fundamental no 👩‍💻 desenvolvimento e no 🏆 sucesso de um projeto. 
            Eles são aqueles que acreditaram na 💡 ideia desde o início e dedicaram seu  ⏰ tempo, 👩‍🏫 esforço e 💰 recursos para torná-la uma realidade 💻📲.
             Além disso, é comum que os fundadores de projetos possuam uma participação societária significativa no empreendimento que ajudaram a criar 💳</p>

            <p>Esses fundadores desempenham um papel crucial na definição da 🎯 visão do projeto, na elaboração da estratégia de execução e na mobilização de recursos necessários para o seu crescimento.
                 Eles são impulsionados pela paixão e pela convicção de que sua ideia pode trazer impacto positivo, seja no âmbito 🤖 tecnológico , 👨‍👩‍👧‍👧 social, 🏦 empres Arial, Helvetica ou em outras áreas.</p>

            <p>Os fundadores de projetos são frequentemente 💡 empreendedores e inovadores, dispostos a assumir riscos e enfrentar desafios 💪 significativos em busca do sucesso do projeto. Sua contribuição não se limita apenas ao início do projeto, pois muitas vezes continuam a liderá-lo e a moldar seu caminho à medida que ele cresce e evolui. 🌱</p>

            <p>Portanto, os fundadores de projetos desempenham um papel fundamental no ecossistema empreendedor e são reconhecidos por sua 🌟 visão, 🏆 determinação e dedicação em transformar ideias inovadoras em realidade. 💻📲🚀</p>
        </div>
    );
};

export default TeamListInfo;
