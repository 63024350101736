import "./css/style.css";

export const ImageDragDrop = ({ images, setImages }) => {

    const handleDragStart = (e, id) => {
        e.dataTransfer.setData('imageId', id);
    };
    const handleDrop = (e, targetIndex) => {
        e.preventDefault();
        const imageId = e.dataTransfer.getData('imageId');
        const draggedImage = images.find(image => image.id === imageId);
        if (draggedImage) {
            const updatedImages = images.filter(image => image.id !== draggedImage.id);
            updatedImages.splice(targetIndex, 0, draggedImage);
            setImages(updatedImages);
        }
    };
    const handleDragOver = e => {
        e.preventDefault();
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const uploadedImages = selectedFiles.map(file => ({
            id: Date.now().toString(),
            file: file,
            filename: file.name,
            src: URL.createObjectURL(file),
        }));
        setImages([...images, ...uploadedImages]);
    };

    return (
        <div>
            <div className="image-container">
                <div className="main-image-container">
                    {images.length > 0 && (
                        <img
                            src={images[0].src}
                            alt={`Imagem Principal`}
                            className="main-image"
                            draggable
                            onDragStart={e => handleDragStart(e, images[0].id)}
                            onDrop={e => handleDrop(e, 0)}
                            onDragOver={handleDragOver}
                        />
                    )}
                    <small>arraste ou solte imagens</small>
                    <div className="flex-wrap col-md-12 d-flex mt-3 captured-image-container justify-content-center  align-items-center">

                        {images.slice(1).map((image, index) => (
                            <div key={`image_${index}`} style={{
                                padding: '5px',
                                marginLeft: '5px',
                                marginRight: "5px",
                            }}
                                className="captured-image-container ">
                                <div className="captured-image-wrapper d-flex col-md-12 justify-content-center  mx-auto">
                                    <img
                                        src={image.src}
                                        alt={`Imagem ${index + 2}`}
                                        className="captured-image"
                                        style={{
                                            minHeight: "60px",
                                            maxHeight: '60px',
                                            minWidth: '60px',
                                            maxWidth: '60px',
                                        }}
                                        draggable
                                        onDragStart={e => handleDragStart(e, image.id)}
                                        onDrop={e => handleDrop(e, index + 1)}
                                        onDragOver={handleDragOver}
                                    />
                                </div></div>
                        ))}
                    </div>
                </div>
            </div>
            <div>
                <div className='mt-2'>
                    <label className="custom-file-upload bg-primary text-white rounded " style={{ padding: '10px' }}>
                        <input type="file" multiple onChange={handleFileChange} name="file" className="hidden-input " />
                        Escolher arquivo
                    </label>
                </div>

            </div>
        </div>
    );
};
export default ImageDragDrop;