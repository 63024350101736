import React from 'react';
import { Apresentacao } from '../layout/Apresentacao';
import { Condutas } from '../layout/Condutas';
import { Significados } from '../layout/Significados';
import { Historia } from '../layout/Historia';
import { Reconhecimentos } from '../layout/Reconhecimentos';
import { Ferramentas } from '../layout/Ferramentas';
import { Linguagem } from '../layout/Linguagem';
import { Team } from '../layout/Team';
import NossosNumeros from '../layout/NossosNumeros';
import { NivelMaturidadeTecnologica } from '../layout/NivelMaturidadeTecnologica';
import TeamList from '../layout/TeamList';
import TeamListColaboradores from '../layout/TeamListColaboradores';
export const items = [
  {
    id: 'apresentacao', title: 'Apresentação', component: <Apresentacao />,
    subItems: [],
  },
  {
    id: 'historia', title: 'Historia', component: <Historia />,
    subItems: [
    ],
  },
  {
    id: 'significados', title: 'Significados', component: <Significados />,
    subItems: [],
  },
  {
    id: 'Condutas', title: 'Condutas', component: <Condutas />,
    subItems: [],
  },
  {
    id: 'Team', title: 'Equipe', component: <Team />,
    subItems: [
      {
        id: 'TeamFundadoresProjetos', title: 'Fundadores de Projetos', component: (
          <>
            <TeamList />
          </>
        ),
      },
      {
        id: 'TeamBolsistasContratados', title: 'Colaboradores', component: (
          <>
            <TeamListColaboradores />

          </>
        ),
      },
    ],
  },
  {
    id: 'ferramentas', title: 'Ferramentas', component: <Ferramentas />,
    subItems: [],
  },
  {
    id: 'linguagem', title: 'Linguagem', component: <Linguagem />,
    subItems: [],
  },
  {
    id: 'maturidade', title: 'Maturidade Tec.', component: <NivelMaturidadeTecnologica />,
    subItems: [],
  },
  {
    id: 'nossosNumeros', title: 'Nossos Números', component: <NossosNumeros />,
    subItems: [],
  },
  {
    id: 'premios', title: 'Prêmios', component: <Reconhecimentos />,
    subItems: [],
  },

];
export default items;