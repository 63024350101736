import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseURL } from '../../../../../../../../../services/api';
import { LoadingModal, MaisInfoMenosInfo, TitleL3 } from '../../../../../../../../../components';
import AnaliseFacialInfo from '../../info/AnaliseFacialInfo';
import { PDFGenerator } from './PDFDownload';
import { Image } from 'antd';
// import FacePhotoCollector from './FacePhotoCollector';
function calcularDistancia(ponto1, ponto2) {
  const dx = ponto1.x - ponto2.x;
  const dy = ponto1.y - ponto2.y;
  return Math.sqrt(dx * dx + dy * dy);
}

function marcarDiferencas(landmarks, pontoDestacado, setPontoDestacado) {
  const limiarDiferencaPequena = 0.05;
  const limiarDiferencaGrande = 0.10;
  const diferenca = [];
  for (let i = 0; i < landmarks.length; i++) {
    if (i % 2 === 0) {
      const pontoDireito = landmarks[i];
      const pontoEsquerdo = landmarks[i + 1];
      const distancia = calcularDistancia(pontoEsquerdo, pontoDireito);
      if (distancia > limiarDiferencaGrande) {
        diferenca.push('red');
      } else if (distancia > limiarDiferencaPequena) {
        diferenca.push('yellow');
      } else {
        diferenca.push(null);
      }
    }
  }
  return landmarks.map((landmark, index) => {
    let cor = 'black';
    if (pontoDestacado === index) {
      cor = 'blue'; // Pode usar outra cor para destacar
    } else if (diferenca[index]) {
      cor = diferenca[index];
    }
    const x = landmark.x !== undefined ? landmark.x.toFixed(2) : 'N/A';
    const y = landmark.y !== undefined ? landmark.y.toFixed(2) : 'N/A';
    const z = landmark.z !== undefined ? landmark.z.toFixed(2) : 'N/A';
    return (
      <small
        key={index}
        style={{ color: cor, cursor: 'pointer' }}
        onClick={() => {
          if (pontoDestacado === index) {
            setPontoDestacado(null); // Clicou novamente, então remove o destaque
          } else {
            setPontoDestacado(index); // Clicou em um novo ponto, destaque-o
          }
        }}
      >
        <br />
        Ponto {index + 1}: X={x}, Y={y}, Z={z}
      </small>
    );
  });
}

export const AnaliseFacial = () => {
  const [image, setImage] = useState(null);
  const [landmarks, setLandmarks] = useState([]);
  const [dark_pixel_coords, setDark_pixel_coords] = useState([]);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [pontoDestacado, setPontoDestacado] = useState(null);
  const [modifiedLandmarks, setModifiedLandmarks] = useState([]);
  const [rugas, setRugas] = useState('')
  const [rightImage, setRightImage] = useState(null);
  const [leftImage, setLeftImage] = useState(null);
  const [faceShape, setFaceShape] = useState(null);
  const [sugestao, setSugestao] = useState([])
  const [predictionsOthers, setPredictionsOthers] = useState([])
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    nomeUsuario: '',
    idade: '',
    nomeEmpresa: '',
    nomeProfissional: '',
    enderecoDigital: '',
    telefone: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'idade') {
      const idade = parseInt(value, 10);
      if (!isNaN(idade)) {
        setUserData({
          ...userData,
          [name]: idade.toString(),
        });
      } else {
        console.error('Idade inválida. Certifique-se de inserir um número válido.');
      }
    } else {
      setUserData({
        ...userData,
        [name]: value,
      });
    }
  };

  const handleImageUpload = async (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    setImage(e.target.files[0]);
    setLoading(true);

    try {
      const response = await axios.post(`${baseURL}analiseFacial`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoading(false);
      setLandmarks(response.data.analysisResult);
      setModifiedLandmarks([...response.data.analysisResult]);
      // Removed setting unused states
      // setInitialCoordinates([...response.data.analysisResult]);
      // setInsertResult(response.data.insertResult);
      setFaceShape(response.data.face_shape);
      const leftImageUrl = `${baseURL}imagem?diretorio=../pages/Private/Profissional/IA/Database/&image=${response.data.combined_image_left}`;
      const rightImageUrl = `${baseURL}imagem?diretorio=../pages/Private/Profissional/IA/Database/&image=${response.data.combined_image_right}`;
      setDark_pixel_coords(response.data.dark_pixel_coords);
      //      alert(response.data.dark_pixel_coords)
      setRugas(Number(response.data.occupied_percentage_rugas_testa));
      setSugestao(response.data.sugestao)
      setLeftImage(leftImageUrl);
      setRightImage(rightImageUrl);
      setPredictionsOthers(response.data.predictionsOthers)
    } catch (error) {
      setLoading(false);
      console.error('Error detecting facial landmarks:', error);
    }
  };

  const handleImageLoad = (e) => {
    setImageWidth(e.target.width);
    setImageHeight(e.target.height);
    createNewLandmark();
  };

  const handlePointMouseDown = (e, index) => {
    e.preventDefault();
    const { clientX, clientY } = e;
    const imageRect = e.target.getBoundingClientRect();
    const offsetX = clientX - imageRect.left;
    const offsetY = clientY - imageRect.top;
    const initialX = modifiedLandmarks[index].x * imageWidth;
    const initialY = modifiedLandmarks[index].y * imageHeight;
    const deltaX = offsetX - initialX;
    const deltaY = offsetY - initialY;
    const updatePointCoordinates = (x, y) => {
      const newModifiedLandmarks = [...modifiedLandmarks];
      if (!newModifiedLandmarks[index]) {
        newModifiedLandmarks[index] = { x: 0, y: 0 };
      }
      newModifiedLandmarks[index].x = (x - deltaX) / imageWidth;
      newModifiedLandmarks[index].y = (y - deltaY) / imageHeight;
      setModifiedLandmarks(newModifiedLandmarks);
    };
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const offsetX = clientX - imageRect.left;
      const offsetY = clientY - imageRect.top;
      updatePointCoordinates(offsetX, offsetY);
    };
    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const createNewLandmark = () => {
    if (modifiedLandmarks.length < 469) {
      const x_11 = modifiedLandmarks[10]?.x * imageWidth;
      const y_11 = modifiedLandmarks[10]?.y * imageHeight;
      const y_10 = modifiedLandmarks[9]?.y * imageHeight;
      if (x_11 !== undefined && y_11 !== undefined && y_10 !== undefined) {
        const x_new = x_11; // O mesmo valor de X do ponto 11
        const y_new = y_11 + (y_11 - y_10); // Y do ponto 11 + a diferença entre Y do ponto 11 e do ponto 10
        const newLandmark = { x: x_new / imageWidth, y: y_new / imageHeight, z: -0.01 };
        const newModifiedLandmarks = [...modifiedLandmarks];
        newModifiedLandmarks[469] = newLandmark;
        setModifiedLandmarks(newModifiedLandmarks);
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && pontoDestacado !== null) {
        setPontoDestacado(null);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [pontoDestacado]);

  const [mostrarInformacoes, setMostrarInformacoes] = useState(false);
  const [mostrarInformacoesValidador, setMostrarInformacoesValidador] = useState(false);

  const handleMostrarInformacoes = () => {
    setMostrarInformacoes(!mostrarInformacoes);
  };

  const handleMostrarInformacoesValidador = () => {
    setMostrarInformacoesValidador(!mostrarInformacoesValidador);
  };


  return (
    <>
      <div className='text-center'>
        <small className='font-weight-bold'><strong>Analise Facial</strong></small>
      </div>
      <div className='d-flex justify-content-center align-items-center col-md-12'>
        <div style={{ position: 'relative' }}>
          {image && (
            <img
              className="d-block w-100 mx-auto "
              src={URL.createObjectURL(image)}
              alt="Uploaded"
              width="auto"
              height="auto"
              onLoad={handleImageLoad}
            />
          )}
          {modifiedLandmarks.map((landmark, index) => (
            (pontoDestacado === null || pontoDestacado === index) && (
              <div
                key={index}
                style={{
                  position: 'absolute',
                  left: landmark && landmark.x !== undefined ? landmark.x * imageWidth : 0,
                  top: landmark && landmark.y !== undefined ? landmark.y * imageHeight : 0,
                  width: '5px',
                  height: '5px',
                  backgroundColor: 'red',
                  cursor: 'pointer',
                }}
                onMouseDown={(e) => handlePointMouseDown(e, index)}
              ></div>
            )
          ))}
        </div>
      </div>
      <div className='d-flex text-center'>
        {leftImage && (
          <div className='col-md-6'>
            <Image src={leftImage} alt="Left Half" width={80} height={80} />
            <TitleL3>Esquerda (Espelhada)</TitleL3>
          </div>
        )}
        {rightImage && (
          <div className='col-md-6'>
            <Image src={rightImage} alt="Right Half" width={80} height={80} />
            <TitleL3>Direita (Espelhada)</TitleL3>
          </div>
        )}
      </div>
      {faceShape && (
        <div className='col-md-12'>
          <TitleL3 className='text-center'>Formato da face: {faceShape}</TitleL3>
        </div>
      )}
      <div className='text-center mt-3 col-md-12'>
        <input className='btn btn-primary form-control' type="file" accept="image/*" onChange={handleImageUpload} />
      </div>
      {/*      
      <FacePhotoCollector /> */}
      {/* Aqui você pode renderizar as miniaturas ou fotos conforme necessário */}


      <MaisInfoMenosInfo text={<AnaliseFacialInfo />}></MaisInfoMenosInfo>
      {image && (<div className='text-center mt-2'>

        <PDFGenerator
          modifiedLandmarks={modifiedLandmarks}
          pontoDestacado={pontoDestacado}
          image={image}
          rightImage={rightImage}
          leftImage={leftImage}
          landmarks={landmarks}
          user={userData.nomeUsuario}
          idade={userData.idade.toString()}
          empresa={userData.nomeEmpresa}
          profissional={userData.nomeProfissional}
          enderecoEletronico={userData.enderecoDigital}
          telefone={userData.telefone}
          dark_pixel_coords={dark_pixel_coords}
          occupied_percentage_rugas_testa={rugas}
          sugestao={sugestao}
          faceShape={faceShape}
          predictionsOthers={predictionsOthers}
        />
        <button
          className={mostrarInformacoesValidador ? "btn btn-warning  w-100 mt-3" : 'btn btn-success  w-100 mt-3'}
          onClick={handleMostrarInformacoesValidador}
        >
          {mostrarInformacoesValidador ? 'Ocultar Informações Validação PDF' : 'Inserir Informações Validação PDF ✅'}
        </button>&nbsp;
        {mostrarInformacoesValidador && (<>
          <div className='col-md-12 text-center'>
            <label className='font-weight-bold text-center'> Nome Usuário:</label><br /> <input
              type='text'
              name="nomeUsuario"
              value={userData.nomeUsuario}
              onChange={handleChange}
              className="configuracao-main-content-input  mb-3 text-center"
              placeholder='Digite o nome do usuário:'
            /><br />
            <label className='font-weight-bold text-center'> Idade:   </label><br /><input
              type='number'
              name="idade"
              value={userData.idade}
              onChange={handleChange}
              min={0}
              className="configuracao-main-content-input  mb-5 text-center"
              placeholder='Digite a idade do usuário'
            />
          </div>
          <div className='col-md-12 text-center'>
            <label className='font-weight-bold text-center'> Nome da Empresa:</label><br /> <input
              type='text'
              name="nomeEmpresa"
              value={userData.nomeEmpresa}
              onChange={handleChange}
              className="configuracao-main-content-input  mb-3 text-center"
              placeholder='Digite o nome da empresa:'
            /><br />
            <label className='font-weight-bold text-center'> Nome do Profissional:</label><br /> <input
              type='text'
              name="nomeProfissional"
              value={userData.nomeProfissional}
              onChange={handleChange}
              className="configuracao-main-content-input  mb-3 text-center"
              placeholder='Digite o nome do profissional:'
            /><br />
            <label className='font-weight-bold text-center'> Endereço digital:</label><br /> <input
              type='text'
              name="enderecoDigital"
              value={userData.enderecoDigital}
              onChange={handleChange}
              className="configuracao-main-content-input  mb-3 text-center"
              placeholder='Digite o endereço eletrônico:'
            /><br />
            <label className='font-weight-bold text-center'> Telefone:</label><br /> <input
              type='text'
              name="telefone"
              value={userData.telefone}
              onChange={handleChange}
              className="configuracao-main-content-input  mb-5 text-center"
              placeholder='Digite o telefone para contato:'
            /><br />
          </div>

        </>)}
        <button
          className={mostrarInformacoes ? "btn btn-warning  w-100 " : 'btn btn-success  w-100 '}
          onClick={handleMostrarInformacoes}
        >
          {mostrarInformacoes ? 'Ocultar Informações de Pontos' : 'Mostrar Alterar Informações de Pontos PDF ⚙️'}
        </button>&nbsp;
      </div>)}
      {mostrarInformacoes && (
        <div>
          {marcarDiferencas(landmarks, pontoDestacado, setPontoDestacado)}
        </div>
      )}
     <LoadingModal 
        visible={loading} 
        message="Carregando dados..." 
        onClose={() => setLoading(false)} // Fechar manualmente o modal
      />
    </>
  );
};

export default AnaliseFacial;
