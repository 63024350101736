import {  TitleL3 } from '../../../../../../../../components';

export const AnaliseFacialInfo = () => {
  return (
    <div>
      <TitleL3 className='font-weight-bold mt-5'>Informações Iniciais de Análise Facial</TitleL3>
      <ul className='ml-3'>
        <li className='mt-2'>
          <strong>✅ Identificação Fácil:</strong> Use uma foto com as mesmas regras de um 3/4 de identidade, o rosto centralizado em um fundo homogêneo que permita que as pessoas o identifiquem facilmente. Evite maquiagem, óculos, chapéus ou outros artefatos que possam obscurecer seu rosto.
        </li>
        <li className='mt-2'>
          <strong>✅ Enquadramento Adequado:</strong> Certifique-se de que sua foto esteja bem enquadrada, com seu rosto centralizado.
        </li>
        <li className='mt-2'>
          <strong>✅ Fundo Homogêneo:</strong> Escolha um local que contenha um fundo homogêneo.
        </li>
        <li className='mt-2'>
          <strong>✅ Iluminação Natural:</strong> Escolha uma foto tirada em boa iluminação natural, pois isso realça sua aparência.
        </li>
        <li className='mt-2'>
          <strong>✅ Resolução Adequada:</strong> Use uma foto de alta resolução para garantir que sua imagem seja nítida e clara.
        </li>
      </ul>
      <TitleL3 className='font-weight-bold mt-5'>Informações Adicionais Análise Facial</TitleL3>
      <ul className='ml-3'>
        <li className='mt-2'>
          <strong>✅ Modificação do Relatório:</strong> Para personalizar o relatório, clique em um ponto na lista abaixo para destacá-lo. Em seguida, clique na imagem e arraste o ponto para o local ideal. O relatório da análise facial será ajustado de acordo com as modificações.
        </li>
      </ul>
      <TitleL3 className='font-weight-bold mt-5'>O que vamos analisar aqui </TitleL3>
      <ul className='ml-3'>
        <li className='mt-2'>
          <strong>✅ Medidas Frontais Verticais:</strong> Fragmentação do rosto em partes que deveriam ter o mesmo tamanho para uma proximidade maior com parametro de beleza "proporção áurea". Terços
          verticais como na imagem a segui:
        </li>
      </ul>
      <p className='text-center'>  <img
        src={require('../assets/analisesVerticais.jpg')}
        alt="Liberdade Financeira"
        style={{ maxHeight: '650px', maxWidth: '650px' }}
        className='mt-5 col-md-12'
      />
      </p>
      <ul className='ml-3'>
        <li className='mt-2'>
          <strong>✅ Medidas Frontais Horizontais:</strong> Fragmentação do rosto em partes que deveriam ter o mesmo tamanho para uma proximidade maior com parametro de beleza "proporção áurea". Terços
          verticais como na imagem a segui:
        </li>
      </ul>
      <p className='text-center'>  <img
        src={require('../assets/analisesHorizontais.jpg')}r
        alt="Liberdade Financeira"
        style={{ maxHeight: '650px', maxWidth: '650px' }}
        className='mt-5 col-md-12'
      />
      </p>
      <ul className='ml-3'>
        <li className='mt-2'>
          <strong>✅ Identidade Facial:</strong> Para sua segurança em procedimentos ou outros colocamos a disposição suas cordenadas de identidade visual facial com dia do exame. Monitor geral de parametros
        </li>
      </ul>
    </div>
  );
};

export default AnaliseFacialInfo;
