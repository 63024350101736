import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Modal } from 'antd';

const DragDropPdf = ({ onPdfUpload }) => {
  const [fileList, setFileList] = useState([]);

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length > 0) {
      onPdfUpload(newFileList[0].originFileObj);
    } else {
      onPdfUpload(null);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Upload
        accept=".pdf"
        action={null}
        listType="picture-card"
        fileList={fileList}
        onChange={handleChange}
        beforeUpload={() => false} // Prevent auto upload
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
      <Modal
        open={false}
      >
        <p>Pré-visualização de PDFs não está disponível.</p>
      </Modal>
    </>
  );
};

export default DragDropPdf;
