import { useLocation } from 'react-router-dom';
import { MaisInfoMenosInfo, IconThotEmotion } from '../../../../../components';
import CadastroAuthInfo from '../info/CadastroAuthInfo';
// import LoginAuthGithub from '../../../Login/partials/layout/LoginAuthGithub';
import LoginAuthGoogle from '../../../Login/partials/layout/LoginAuthGoogle';
// import LoginAuthFacebook from '../../../Login/partials/layout/LoginAuthFacebook';
export const CadastroAuth = ({ onclickCadastroNativo, onclickLoginNativo }) => {
  const location = useLocation();
  const isCadastrarPage = location.pathname.includes("/cadastrar");
  return (<>
    <div className='text-center mt-3'>
      <button className=" btn text-white btn-primary " type={'button'} onClick={isCadastrarPage ? onclickCadastroNativo : onclickLoginNativo}>
        <i className="text-white border bg-white rounded"> <IconThotEmotion /></i>
      </button></div>
    <div className='text-center mt-2'>
      <small>{isCadastrarPage ? 'Cadastro' : 'Login'} Nativo</small></div>
    <div className='d-flex text-center justify-content-center mt-3'>
      <LoginAuthGoogle />
      &nbsp;
      {/* <LoginAuthFacebook />
        &nbsp;
        <LoginAuthGithub /> */}
    </div>
    <div className='text-center mt-2'>
      <small>Cadastro ou Login Rápidos</small></div>
    <div className='mb-4'>
      <MaisInfoMenosInfo text={<CadastroAuthInfo />}></MaisInfoMenosInfo>
    </div>
  </>);
};
export default CadastroAuth;