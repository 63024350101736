import React from "react";
import { Nav, NavProfissionalMain, NavBreadcrumb } from "../../../../../../components";
import { EscolheIAImagem } from "./partials/layout/EscolheIAImagem";
export const ImagemIA = () => {
  return (<>
    <Nav />
    <div className="d-flex mt-3">
      <NavProfissionalMain />    
        <section className='w-100'>
          <NavBreadcrumb text={'I.A Imagem'} icon={'crosshairs fa'} />
          <div className="row d-inline-flex justify-content-center w-100" >
            <EscolheIAImagem />
          </div>
        </section>      
    </div>
  </>);
};

export default ImagemIA;