import { Main } from "./components/Main";
import { FooterUser, Nav, NavUserMain } from "../../../../components";
export const Cadastro = () => {
  return (<>
    <Nav />
    <div className="d-flex mt-3" style={{overflowX:'hidden'}}>
      <NavUserMain />
      <section className="w-100">
        <Main />
        <FooterUser />
      </section>
    </div>
  </>)
}