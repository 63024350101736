import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Checkbox, Row, Col } from 'antd';
import { ControleProfissional } from '../Controls/ControleProfissional';
import {
    ReferenciaLocal,
    MaisInfoMenosInfo,
    TermoDeResponsabilidadeCadastro,
    Nav,
    NavUserMain,
    NavBreadcrumb,
} from '../../../../../../components';
import { PerfilCadastroProfissionalInfo } from './components/info/PerfilCadastroProfissionalInfo';
import { CadastrarClinicaInstituicao } from '../CadastroEmpresa/CadastroClinicaInstituicao';
import { CadastrarProfissional } from './components/layout/CadastrarProfissional';
import ProfissionalVinculado from './components/layout/ProfissionalVinculado';
import { axiosHeadersToken } from '../../../../../../services/contexts';
import { baseURL } from '../../../../../../services/api';
import { AuthContext } from '../../../../../../contexts/auth';
const useCheckboxController = () => {
    const checkboxNames = [
        'Clínica',
        'Profissional Autônomo',
        'Profissional Vinculado',
        'Pais',
        'Estado',
        'Cidade',
        'Instituição',
        'Servidor',
        'Aluno',
    ];
    const initialState = checkboxNames.reduce((acc, name) => {
        acc[name.toLowerCase().replace(/\s/g, '_')] = false;
        return acc;
    }, {});
    const [checkboxes, setCheckboxes] = useState(initialState);
    const handleCheckboxChange = (checkboxName) => {
        setCheckboxes((prevCheckboxes) => {
            const updatedCheckboxes = { ...prevCheckboxes };
            Object.keys(updatedCheckboxes).forEach((name) => {
                if (name !== checkboxName) {
                    updatedCheckboxes[name] = false;
                }
            });
            updatedCheckboxes[checkboxName] = !prevCheckboxes[checkboxName];
            return updatedCheckboxes;
        });
    };
    const isCheckboxSelected = Object.values(checkboxes).some((value) => value);
    return {
        checkboxNames,
        checkboxes,
        handleCheckboxChange,
        isCheckboxSelected,
    };
};
export const CadastroProfissional = () => {
    const [loading, setLoading] = useState(true);
    const { getUserId } = useContext(AuthContext);
    const { getProfessionalId } = useContext(AuthContext);
    const professional_id = getProfessionalId();
    useEffect(() => {
        const cadastroProfissional = localStorage.getItem('cadastroProfissional');
        if (cadastroProfissional === 'SIM') {
            localStorage.removeItem('cadastroProfissional');
        }
    }, []);
    const { checkboxes, handleCheckboxChange, isCheckboxSelected } = useCheckboxController();
    const [aceitouTermo, setAceitouTermo] = useState(true);
    useEffect(() => {
        setAceitouTermo(Object.values(checkboxes).some((value) => value));
    }, [checkboxes]);

    const handleCheckboxItemClick = (type) => {
        localStorage.setItem('checkbox', type);
    };

    const fetchData = useCallback(async () => {
        const id = getUserId();
        if (professional_id === null) {
            setLoading(true);
            try {
                await axiosHeadersToken().get(`${baseURL}get_professional_state_deleted/${id}`);
            } catch (error) {
                console.error('Erro ao buscar estado na API:', error);
              
            } finally {
                setLoading(false);
            }
        }
    }, [getUserId, professional_id]);
    useEffect(() => {
        fetchData();
    }, [fetchData, getProfessionalId]);

    return (<>
        <Nav />
        <div className="d-flex mt-3">
            <NavUserMain />
            <div className="w-100 ">
                <section>
                    <NavBreadcrumb icon={'user fa'} text={'Cadastro Profissional'} />
                    {professional_id && <>    <ReferenciaLocal text={'Cadastros Profissionais Ativos'} /></>}
                    <ControleProfissional />
                    {loading && loading}
                    <ReferenciaLocal text={'Efetuar '} text2={'Cadastro Profissional'} />
                    {professional_id && <>   <div className='col-md-12 mt-5 text-center'>
                        <div className='text-center'>
                            <TermoDeResponsabilidadeCadastro aceitouTermo={aceitouTermo} setAceitouTermo={setAceitouTermo} professional_id={professional_id} />
                        </div>
                    </div></>}
                    <div className='col-md-10 mx-auto'>
                        <div id="perfilDeCadastroProfissional" className="form-group py-5">
                            <div className={`text-center d-flex justify-content-center col-lg-12 boxFooter ${isCheckboxSelected ? 'bg-success' : 'bg-primary'} TridimencionalSupLat`}>
                                <h5 className="text-white mt-2 font-weight-bold"> <b>Perfil de Cadastro Profissional</b></h5>
                            </div>
                            <div className={`CardTHOT py-3 ${isCheckboxSelected ? 'bg-light' : 'bg-light'}`} >
                                <Row>
                                    <Col span={24} className="text-left mt-2">
                                        <fieldset className="fieldset-border">
                                            <legend className="legend-border font-weight-bold text-dark py-3">Privado</legend>
                                            {Object.entries(checkboxes).slice(0, 3).map(([name, checked]) => (<>
                                                <Checkbox
                                                    key={name}
                                                    id={name}
                                                    checked={checked}
                                                    onChange={() => {
                                                        handleCheckboxChange(name);
                                                        handleCheckboxItemClick("privado");
                                                    }}
                                                >
                                                    {name.charAt(0).toUpperCase() + name.slice(1)}
                                                    {name === "profissional_autônomo" && <small className='ml-3 text-primary'>[ Master Cadastro Profissional ]</small>}
                                                </Checkbox>
                                                <br /><br />
                                            </>))}
                                        </fieldset>
                                    </Col>
                                    <Col span={24} className="text-left mt-2 mb-3">
                                        <fieldset className="fieldset-border">
                                            <legend className="legend-border font-weight-bold text-dark py-3">Governamental</legend>
                                            {Object.entries(checkboxes).slice(3, 8).map(([name, checked]) => (<>
                                                <Checkbox
                                                    key={name}
                                                    checked={checked}
                                                    onChange={() => {
                                                        handleCheckboxChange(name);
                                                        handleCheckboxItemClick("governamental");
                                                    }}
                                                >
                                                    {name.charAt(0).toUpperCase() + name.slice(1)}
                                                </Checkbox>
                                                <br /><br />
                                            </>))}
                                        </fieldset>
                                    </Col>
                                    <Col span={24} className="text-left mt-2 mb-3">
                                        <fieldset className="fieldset-border">
                                            <legend className="legend-border font-weight-bold text-dark py-3">Aspirante</legend>
                                            {Object.entries(checkboxes).slice(8, 9).map(([name, checked]) => (
                                                <Checkbox
                                                    key={name}
                                                    checked={checked}
                                                    onChange={() => {
                                                        handleCheckboxChange(name);
                                                    }}
                                                >
                                                    {name.charAt(0).toUpperCase() + name.slice(1)}
                                                </Checkbox>
                                            ))}
                                        </fieldset>
                                    </Col>
                                </Row>
                            </div>
                            <div className={`text-center d-flex justify-content-center align-items-center ${isCheckboxSelected ? 'bg-success' : 'bg-primary'} TridimencionalInfLagerLat py-2`}>
                                <h5 className="text-white rounded  mt-1 justify-content-center align-items-center d-flex">
                                    <b> {isCheckboxSelected ? <i className="fa fa-check" aria-hidden="true"></i> : '...'} </b>
                                </h5>
                            </div>
                            <div className="col-md-12 text-center mt-5">
                                <MaisInfoMenosInfo text={<PerfilCadastroProfissionalInfo />} />
                            </div>
                        </div>
                        {checkboxes['clínica'] ? <CadastrarClinicaInstituicao /> : null}
                        {checkboxes['profissional_autônomo'] ? <CadastrarProfissional title={professional_id ? "Editar Profissional Autônomo" : 'Profissional Autônomo'} /> : null}
                        {checkboxes['profissional_vinculado'] ? <ProfissionalVinculado allowedOptions={[
                            "Profissional",
                            "Administrador",
                            "Desenvolvedor",
                            "Analista de Sistemas",
                            "Consultor",
                            "Gerente de Projeto",
                            "Analista de Dados",
                            "Analista Financeiro",
                            "Analista de Recursos Humanos"
                        ]} /> : null}
                        {checkboxes['país'] ? <h5>Cadastrar estado</h5> : null}
                        {checkboxes['estado'] ? <h5>Cadastrar cidade</h5> : null}
                        {checkboxes['cidade'] ? <CadastrarClinicaInstituicao /> : null}
                        {checkboxes['instituição'] ? <CadastrarClinicaInstituicao /> : null}
                        {checkboxes['servidor'] ? <ProfissionalVinculado allowedOptions={[
                            "Profissional",
                            "Administrador",
                            "Desenvolvedor",
                            "Analista de Sistemas",
                            "Consultor",
                            "Gerente de Projeto",
                            "Analista de Dados",
                            "Analista Financeiro",
                            "Analista de Recursos Humanos"
                        ]} /> : null}
                        {checkboxes['aluno'] ? <ProfissionalVinculado allowedOptions={[
                            "Aluno"
                        ]} /> : null}
                    </div>

                </section>
            </div>
        </div>
    </>);
};
export default CadastroProfissional;