import { CardDirecionamento, NavBreadcrumb } from '../../../../components';
export const Index = () => {
    return (<>
        <div className='w-100'>
            <NavBreadcrumb icon={'suitcase fa'} text={'Serviços'} />
            <div className="py-3">
                <div className="text-center">
                    <div className="container-fluid">
                        <div className="row d-inline-flex justify-content-center w-100">
                            <CardDirecionamento text={'Orçamento'} icon={'mt-3 fa fa-3x fa-calendar text-white'} href={'../../../profissionais/servicos/orcamento'} />
                            <CardDirecionamento text={'Aprovados'} icon={'mt-3 fa fa-3x fa-location-arrow text-white'} href={'../../../profissionais/servicos/aprovados'} />
                            <CardDirecionamento text={'Finalizados'} icon={'mt-3 fa fa-database fa-3x text-white'} href={'../../../profissionais/servicos/finalizados'} />
                            <CardDirecionamento text={'Cadastrar / Editar'} icon={'mt-3 fa fa-edit  fa-3x text-white'} href={'../../../profissionais/servicos/cadastrar'} />               
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}