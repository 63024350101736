import React from 'react';

export const handleConfirmPasswordChange = (event, setConfirmPassword) => {
    setConfirmPassword(event.target.value);
};

export const handlePasswordChange = (event, setSecurityLevel, setPassword) => {
    setPassword(event.target.value);
    const currentSecurityLevel = checkPasswordSecurity(event.target.value);
    setSecurityLevel(currentSecurityLevel);
};

export const checkPasswordSecurity = (password) => {
    let securityLevel = 0;
    if (password.length >= 8) {
        securityLevel++;
    }
    if (
        /[a-z]/.test(password) &&
        /[A-Z]/.test(password) &&
        /[0-9]/.test(password) &&
        /[!@#$%^&*()]/.test(password)
    ) {
        securityLevel++;
    }
    return securityLevel;
};

export const getSecurityBarColor = (securityLevel) => {
    switch (securityLevel) {
        case 0:
            return "red";
        case 1:
            return "yellow";
        case 2:
            return "green";
        default:
            return "red";
    }
};

export const generateSecurePassword = (setPassword, setConfirmPassword, setSecurityLevel,) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
    let securePassword = "";
    for (let i = 0; i < 16; i++) {
        securePassword += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setPassword(securePassword);
    setConfirmPassword(securePassword);
    const securityLevel = checkPasswordSecurity(securePassword);
    setSecurityLevel(securityLevel);
};
export const SecurityBars = ({ securityLevel }) => {
    const securityBars = [];
    for (let i = 0; i < 3; i++) {
        securityBars.push(
            <div
                key={i}
                className="input-group border border-dark rounded"
                style={{
                    height: "10px",
                    width: "5%",
                    backgroundColor: securityLevel >= i ? getSecurityBarColor(i) : "#ccc",
                }}
            />
        );
    }
    return <div className="security-bars">{securityBars}<small>Nível de seg. senha</small></div>;
};
