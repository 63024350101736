import React from 'react';
export const TeamListVoluntarioInfo = () => {
    return (<>
        <section>
            <div className="text-justify">
                <h5 className="font-weight-bold mt-5">Regime de Colaboradores Voluntários: Unindo Forças para um Mundo Melhor</h5>
               
                <h5 className="mt-5">
                    Aqui, somos guiados por paixão e dedicação, trabalhamos juntos para fazer a diferença na comunidade e além.
                    Nossos voluntários são verdadeiros heróis, oferecendo seu tempo e talento de forma altruísta.
                    Assim juntos, formamos uma equipe comprometida em tornar o mundo um lugar mais brilhante e inclusivo
                </h5>
                <h5>
                    Utilizamos uma variedade de
                    ferramentas e tecnologias modernas para impulsionar nossos projetos e causas.
                    Desde o desenvolvimento de software até a pesquisa e o design criativo, nossos colaboradores voluntários
                    trazem suas habilidades e conhecimentos únicos para a mesa nesse sentido
                </h5>
                <h5>
                    Além de contribuir para causas que acreditamos, 
                    também valorizamos o aprendizado contínuo e o crescimento pessoal.
                    Estamos comprometidos em criar um impacto positivo, tanto individualmente quanto coletivamente.
                    Se você também acredita na importância de dar um passo à frente, junte-se a nós! Estamos sempre à procura de
                    novos colaboradores voluntários dispostos a fazer a diferença.
                    Juntos, podemos construir um mundo melhor e mais compassivo
                </h5>
            </div>
        </section>
    </>);
};
export default TeamListVoluntarioInfo;