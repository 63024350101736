import React, { useEffect, useRef, useState } from 'react';
import { AntButton, ButtonCadastrar, CardFragmentIdentifier, TitleL1, TitleL3 } from '../../../../../components';
import predioTHOT from '../assets/predioTHOT.png';
import {
    MdHistoryEdu,
    MdOutlineCoPresent,
    MdOutlineCastForEducation,
    MdOutlineDiversity2,
    MdHandyman,
    MdTranslate,
    MdInsights,
    MdTroubleshoot,
    MdInfoOutline,
    MdOutlineEmojiEvents,
} from "react-icons/md";
import { Button, Carousel } from 'antd';
// import SatisfactionLevels from './SatisfactionLevels';
import EcosystemMenu from './EcosystemMenu';
export const Apresentacao = () => {
    const carouselRef = useRef(null);
    const images = [
        { id: 1, url: predioTHOT, type: 'image' },
        { id: 2, url: 'https://www.youtube.com/embed/Fp3KZO3u-sg', type: 'video' }
    ];
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);
    const handleWindowResize = () => {
        setScreenWidth(window.innerWidth);
    };
    return (<>
        <section className='mr-2 ml-2' style={{ minHeight: '95dvh' }}>
            <TitleL1 icon={<MdInfoOutline style={{ scale: '2' }} />}>
                Informações Globais
            </TitleL1>
            <div className="text-center py-3 mt-4 bg-secondary trl-container " style={{ borderRadius: '0.25rem', position: 'relative' }}>

                <div className='row'>
                    <div className='col-md-12'><h5 className="text-justify text-white mt-3">
                        Empresa especializada no desenvolvimento de
                        softwares e equipamentos com [ Inteligência Artificial Integrada ].
                        Nossa atuação abrange áreas como:

                    </h5>
                    </div>
                    <div className={` ${screenWidth >= 1058 ? 'col-md-6' : 'col-md-12'}`}>
                        <h5 className="text-justify text-white mt-3">
                            <ul className='mt-3'>
                                <li>✅ IA (Inteligência Artificial)</li>
                                <li>✅ Visão Computacional</li>
                                <li>✅ IoT (Internet das Coisas)</li>
                                <li>✅ Big Data</li>
                                <li>✅ Web Dev.</li>
                                <li>✅ E afins</li>
                            </ul>
                        </h5>
                        <div className='text-left mt-2 py-2'>
                            <AntButton
                                href='../parcerias'
                                style={{ cursor: 'pointer' }}
                                className=' text-white font-weight-bold bnt btn-primary'
                                label={'Faça parcerias conosco aqui'}
                            /><br />
                            <AntButton
                                href='../produtos'
                                style={{ cursor: 'pointer', }}
                                className=' text-white font-weight-bold bnt btn-primary mt-1'
                                label={'Compre capital social aqui'}
                            />
                        </div>
                    </div>
                    {screenWidth >= 1058 && <>
                        <div className='col-md-6 d-flex justify-content-center'>
                            <div style={{ position: 'relative', width: '100%', maxWidth: '600px', margin: '0 auto' }}>
                                <Button
                                    style={{
                                        position: 'absolute',
                                        top: '40%',
                                        left: '0',
                                        transform: 'translateY(-50%)',
                                        zIndex: 1,
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        fontSize: '24px',
                                        color: '#000',
                                    }}
                                    onClick={() => carouselRef.current.prev()}
                                >
                                    ◀
                                </Button>
                                <Carousel ref={carouselRef} dots={{ className: 'custom-dots' }} autoplay={false}>
                                    {images.map((item) => (
                                        <div key={item.id}>
                                            {item.type === 'image' ? (
                                                <img
                                                    className="w-100 rounded image-fluid"
                                                    style={{
                                                        height: '270px',
                                                        borderRadius: '30px',
                                                        border: '1px light',
                                                        boxShadow: '1px 1px 2px #000000',
                                                    }}
                                                    src={item.url}
                                                    alt="Produto Imagem"
                                                />
                                            ) : (
                                                <iframe
                                                    className="w-100 rounded"
                                                    style={{
                                                        borderRadius: '30px',
                                                        border: '1px light',
                                                        boxShadow: '1px 1px 2px #000000',
                                                        height: '270px'
                                                    }}
                                                    src={item.url}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                    title="YouTube Video"
                                                />
                                            )}
                                        </div>
                                    ))}
                                </Carousel>
                                <Button
                                    style={{
                                        position: 'absolute',
                                        top: '40%',
                                        right: '0',
                                        transform: 'translateY(-50%)',
                                        zIndex: 1,
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        fontSize: '24px',
                                        color: '#000',
                                    }}
                                    onClick={() => carouselRef.current.next()}
                                >
                                    ▶
                                </Button>
                            </div>


                        </div>
                        <div className='iconTRL-links col-md-6'>

                            {/* <SatisfactionLevels /> */}
                        </div>
                        <EcosystemMenu />
                    </>}
                </div>
            </div>
            <TitleL3 className='mt-5'>⏣ Mais informações gerais da empresa</TitleL3>
            <div className='mb-5 d-flex flex-wrap justify-content-center '>
                <CardFragmentIdentifier href={'historia'} title={'História'} text={'Como foi a construção da empresa da ideia à evolução'} icon={<MdHistoryEdu style={{ scale: '5' }} />} />
                <CardFragmentIdentifier href={'significados'} title={'Significados'} text={'Branding marketing, criação do nome, logo, paleta de cores'} icon={<MdOutlineCoPresent style={{ scale: '4' }} />} />
                <CardFragmentIdentifier href={'Condutas'} title={'Condutas'} text={'Modo de agir da empresa matriz, objetivo, missão, visão'} icon={<MdOutlineCastForEducation style={{ scale: '4' }} />} />
                <CardFragmentIdentifier href={'Team'} title={'Equipe'} text={'Pessoas que participaram de alguma forma na construção'} icon={<MdOutlineDiversity2 style={{ scale: '4' }} />} />
            </div>
            <TitleL3 className='mt-5'>⏣ Mais informações técnicas da empresa</TitleL3>
            <div className='mb-5 d-flex flex-wrap justify-content-center '>
                <CardFragmentIdentifier href={'ferramentas'} title={'Ferramentas'} text={'Referências da construção, propagação e expansão'} icon={<MdHandyman style={{ scale: '4' }} />} />
                <CardFragmentIdentifier href={'linguagem'} title={'Linguagem'} text={'Material de orientação computacional'} icon={<MdTranslate style={{ scale: '4' }} />} />
                <CardFragmentIdentifier href={'maturidade'} title={'Maturidade'} text={'Domínio das tecnologias da plataforma'} icon={<MdInsights style={{ scale: '4' }} />} />
                <CardFragmentIdentifier href={'nossosNumeros'} title={'Nossos Números'} text={'Métricas de visualizações, utilizações'} icon={<MdTroubleshoot style={{ scale: '4' }} />} />
            </div>
            <TitleL3 className='mt-5'>⏣ Conquistas da empresa</TitleL3>
            <div className='mb-5 d-flex flex-wrap justify-content-center '>
                <CardFragmentIdentifier href={'premios'} title={'Prêmios'} text={'Conquistas externas da equipe empresarial'} icon={<MdOutlineEmojiEvents style={{ scale: '4' }} />} />
            </div>
            {screenWidth <= 1058 && <>
                <div className='py-4 d-flex justify-content-center'>
                    <div style={{ position: 'relative', width: '100%', maxWidth: '600px', margin: '0 auto' }}>
                        <Button
                            style={{
                                position: 'absolute',
                                top: '40%',
                                left: '0',
                                transform: 'translateY(-50%)',
                                zIndex: 1,
                                backgroundColor: 'transparent',
                                border: 'none',
                                fontSize: '24px',
                                color: '#000',
                            }}
                            onClick={() => carouselRef.current.prev()}
                        >
                            ◀
                        </Button>
                        <Carousel ref={carouselRef} dots={{ className: 'custom-dots' }} autoplay={false}>
                            {images.map((item) => (
                                <div key={item.id}>
                                    {item.type === 'image' ? (
                                        <img
                                            className="w-100 rounded image-fluid"
                                            style={{
                                                height: '270px',
                                                borderRadius: '30px',
                                                border: '1px light',
                                                boxShadow: '1px 1px 2px #000000',
                                            }}
                                            src={item.url}
                                            alt="Produto Imagem"
                                        />
                                    ) : (
                                        <iframe
                                            className="w-100 rounded"
                                            style={{
                                                borderRadius: '30px',
                                                border: '1px light',
                                                boxShadow: '1px 1px 2px #000000',
                                                height: '270px'
                                            }}
                                            src={item.url}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                            title="YouTube Video"
                                        />
                                    )}
                                </div>
                            ))}
                        </Carousel>
                        <Button
                            style={{
                                position: 'absolute',
                                top: '40%',
                                right: '0',
                                transform: 'translateY(-50%)',
                                zIndex: 1,
                                backgroundColor: 'transparent',
                                border: 'none',
                                fontSize: '24px',
                                color: '#000',
                            }}
                            onClick={() => carouselRef.current.next()}
                        >
                            ▶
                        </Button>
                    </div>
                </div>

                {/* <div className='iconTRL-links col-md-6'>

                    <SatisfactionLevels />
                </div> */}
           </>}
            <ButtonCadastrar />
        </section>
    </>
    );
};

export default Apresentacao;
