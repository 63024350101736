import { Typography } from 'antd';
import { useEffect, useState } from 'react';
const { Title } = Typography;

export const TitleL1 = (props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (<>
    <Title
      level={1}
      style={{
        textAlign: 'center',
        padding:'10px',
        ...props.style
      }}
      className={`mt-3 font-weight-bold ${props.className}`}
    >
      <strong
        style={{
          fontFamily: ' Arial, Helvetica',
          fontWeight: '800',
          ...props.style
        }}
      >
        {screenWidth >= 1058 &&
          <>
            {props.icon &&
              <>
                {props.icon}&emsp;
              </>}
          </>}
        {props.children}</strong>
    </Title>
  </>);
};
export default TitleL1;