import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { Badge } from 'antd';
export const Notificacoes = () => {
    const [notificacoes, setNotificacoes] = useState('0');
    const post = () => {
        setNotificacoes('0');
    };
    const location = useLocation();
    const isProfissionaisPage = location.pathname.startsWith("/profissionais");
    const logout = () => {
        localStorage.clear();
        window.location.reload();
    };
    return (<>
        <div className="d-flex align-items-center justify-content-center py-4">
            <div className="text-center">
                <i className="fa fa-power-off text-danger" onClick={logout}></i>
                <a href={isProfissionaisPage ? '../../../../profissionais/notificacoes' : '../../../../usuario/notificacoes'} className="" onClick={post}>
                    &emsp;&emsp;
                    <Badge count={notificacoes > 0 ? notificacoes : 0} overflowCount={99}>
                        <i className="fa fa-bell text-warning" aria-hidden="true"></i>
                    </Badge>
                </a>
            </div>
        </div>
    </>);
};
