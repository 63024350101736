import React, { useEffect } from 'react';
import { Card, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

export const NotificationSystem = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.user_type === 'usuario') {
      // Show notification
      document.getElementById('notification-card').style.display = 'block';
    }
  }, []);

  const handleRedirect = () => {
    navigate('/cadastrar-profissional');
  };

  return (
    <Card id="notification-card" style={{ display: 'none', marginTop: 16 }}>
      <p>Caso seja um profissional. Por favor, complete seu cadastro profissional</p>
      <Button type="primary" onClick={handleRedirect}>
        Cadastro Profissional
      </Button>
    </Card>
  );
};

export default NotificationSystem;
