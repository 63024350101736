import React, { useState } from 'react';
import TeamCard from './TeamCard';
import { ButtonCadastrar, InputPadraoSearch, MaisInfoMenosInfo } from '../../../../../components';
import TeamListBolsistaInfo from '../info/TeamListBolsistaInfo';
import { Pagination } from 'antd';

export const TeamListColaboradores = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6);

    const teamMembers = [
        {
            name: 'Estêvão Barata',
            title: 'Cientista da Computação - Desenvolvedor de softwares',
            imageSource: require('../assets/team/mario.jpg'),
            lattesLink: '',
            linkedinLink: 'https://www.linkedin.com/in/barata-barata-b462101b0/',
            githubLink: 'https://github.com/BarataBarata',
        },
        {
            name: 'Anderson Willian',
            title: 'Engenheiro de Software - Desenvolvedor de softwares',
            imageSource: 'https://avatars.githubusercontent.com/u/72330142?v=4',
            lattesLink: '',
            linkedinLink: 'https://www.linkedin.com/in/andersonwsf/',
            githubLink: 'https://github.com/T3lo5',
        },
        {
            name: 'Reuven Martins',
            title: 'Cientista da Computação - Desenvolvedor de softwares',
            imageSource: 'http://servicosweb.cnpq.br/wspessoa/servletrecuperafoto?tipo=1&id=K1109074A7',
            lattesLink: 'http://lattes.cnpq.br/0368614623147946',
            linkedinLink: 'https://www.linkedin.com/in/reuven-martins-28937925b/',
            githubLink: 'https://github.com/reuven007',
        },
        {
            name: 'Anabelly Passos',
            title: 'Engenheira de Software - Desenvolvedora de softwares',
            imageSource: 'https://avatars.githubusercontent.com/u/123272360?v=4',
            lattesLink: 'Lattes:http://lattes.cnpq.br/5504230407216193',
            linkedinLink: 'https://www.linkedin.com/in/anabelly-passos-a4b44623b/',
            githubLink: 'https://github.com/anabellypassos',
        },
        {
            name: 'Juan Augusto',
            title: 'Engenheiro Eletricista - Desenvolvedor de hardwares',
            imageSource: 'https://avatars.githubusercontent.com/u/112441846?v=4',
            lattesLink: 'https://lattes.cnpq.br/8164448410293255',
            linkedinLink: '',
            githubLink: 'https://github.com/juanaugusto007',
        },
        {
            name: 'Cleyson Oliveira',
            title: 'Cientista da Computação - Desenvolvedor de softwares',
            imageSource: 'https://avatars.githubusercontent.com/u/109116643?v=4',
            lattesLink: 'https://lattes.cnpq.br/3971914300301258',
            linkedinLink: 'https://www.linkedin.com/in/cleysonoliveira/',
            githubLink: 'https://github.com/CleysonOlvr',
        },
        {
            name: 'Felipe Martines',
            title: 'Cientista da Computação - Desenvolvedor de softwares',
            imageSource: 'https://avatars.githubusercontent.com/u/128336634?v=4',
            lattesLink: 'https://lattes.cnpq.br/3956074761861751',
            linkedinLink: 'https://www.linkedin.com/in/felipe-m-kurjata/',
            githubLink: 'https://github.com/Kurjata',
        },
        {
            name: 'Leonardo Rodrigues',
            title: 'Analista e Desenvolvedor De Sistemas - Desenvolvedor de softwares',
            imageSource: 'http://servicosweb.cnpq.br/wspessoa/servletrecuperafoto?tipo=1&id=K1107304T6',
            lattesLink: 'http://lattes.cnpq.br/7093628692864558',
            linkedinLink: 'https://www.linkedin.com/in/leonardo-2864471b7/',
            githubLink: 'https://github.com/LeonardoRSantos',
        },
        {
            name: 'Rui Carlos Timo',
            title: 'Representante Comercial - Marketing vendas',
            imageSource: require('../assets/team/rui.jpg'),
            lattesLink: '',
            linkedinLink: 'https://www.linkedin.com/in/rui-timo-47966a159/',
            githubLink: '',
        },
        {
            name: 'Júlio César',
            title: 'Cientista da Computação - Desenvolvedor de softwares',
            imageSource: require('../assets/team/julio.png'),
            lattesLink: 'https://lattes.cnpq.br/4370422880620071',
            linkedinLink: 'https://www.linkedin.com/in/j%C3%BAlio-ara%C3%BAjo-31913a89/',
            githubLink: 'https://github.com/juliotecno',
        },
        {
            name: 'Guilherme Melo',
            title: 'Engenheiro Sanitarista - Tradutor e coordenador de equipe internacional',
            imageSource: require('../assets/team/11.jpg'),
            lattesLink: '',
            linkedinLink: 'https://www.linkedin.com/in/j%C3%BAlio-ara%C3%BAjo-31913a89/',
            githubLink: '',
        },
        {
            name: 'Guilherme Fialho',
            title: 'Engenheiro de Produção - Logística',
            imageSource: require('../assets/team/6.jpg'),
            lattesLink: '',
            linkedinLink: '',
            githubLink: '',
        },
    ];

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const filteredTeamMembers = teamMembers.filter((member) =>
        member.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredTeamMembers.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate the number of total pages
    const totalPages = Math.ceil(filteredTeamMembers.length / itemsPerPage);

    return (
        <>
            <section style={{ minHeight: '95dvh' }}>
                <InputPadraoSearch
                    type="text"
                    placeholder="Pesquisar por nome..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div>
                    {currentItems.map((member, index) => (
                        <TeamCard
                            key={index}
                            name={member.name}
                            title={member.title}
                            imageSource={member.imageSource}
                            lattesLink={member.lattesLink}
                            linkedinLink={member.linkedinLink}
                            githubLink={member.githubLink}
                        />
                    ))}
                </div>
                <div className="pagination text-center col-md-12 mx-auto justify-content-center py-4">
                    {totalPages > 1 && (
                        <Pagination
                            current={currentPage}
                            pageSize={itemsPerPage}
                            total={filteredTeamMembers.length}
                            onChange={handlePageChange}
                        />
                    )}
                </div>
                <div className="py-4 text-center">
                    <MaisInfoMenosInfo text={<><TeamListBolsistaInfo /></>} />
                </div>
                <ButtonCadastrar />
            </section>
        </>
    );
};

export default TeamListColaboradores;
