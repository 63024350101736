import React, { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../../../../../../contexts/auth.jsx';
import { baseURL } from '../../../../../../services/api.js';
import { ModalSimples, TitleL2 } from '../../../../../index.js';
import FotoFace from '../../../../../../pages/Private/Usuarios/Cadastro/components/CadastroPaciente/layout/FotoFace.jsx';
import { ControleProfissional } from '../../../../../../pages/Private/Usuarios/Cadastro/components/Controls/ControleProfissional.jsx';
import { fetchProfessionalImage, fetchUserPhoto } from '../../../../../../services/index.js';
import { useDriveUser } from './useDriveUser.js';

export const ImagemPerfilUsuario = () => {
  const { getUserId, getProfessionalId } = useContext(AuthContext);
  const id = getUserId();
  const professional_id = getProfessionalId();
  const { driveUser, clearDriveUser } = useDriveUser();
  const [imageUrl, setImageUrl] = useState(require('./Imagem/user.png'));
  const [imageLogoAtiva, setImageLogoAtiva] = useState(require('./Imagem/user.png'));

  const fetchUserPhotoBlob = async (id) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${baseURL}user/${id}/photo`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Erro ao buscar a imagem do usuário');
    }
    return response.blob();
  };

  useEffect(() => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    if (user.imageUrlsUser !== null) {
      const fetchUserImage = async () => {
        try {
          const userImageBlob = await fetchUserPhotoBlob(id);
          const userImageUrl = URL.createObjectURL(userImageBlob);
          setImageUrl(userImageUrl);
        } catch (error) {
          console.error(error);
        }
      };
      fetchUserImage();
    }
  }, [id]);

  useEffect(() => {
    if (professional_id) {
      fetchProfessionalImage(professional_id, setImageLogoAtiva);
    }
  }, [professional_id]);

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [isVisiblelogoAtivaOpenFullScreen, setIsVisiblelogoAtivaOpenFullScreen] = useState(false);
  const logoAtivaOpenFullScreen = () => {
    setIsVisiblelogoAtivaOpenFullScreen(true);
  };
  const logoAtivaCloseFullScreen = () => {
    setIsVisiblelogoAtivaOpenFullScreen(false);
  };
  const handlePhotoUpdated = useCallback(() => {
    fetchUserPhoto(id, setImageUrl);
  }, [id]);
  const [isDriveUserOpenFullScreen, setIsDriveUserOpenFullScreen] = useState(false);

  const isDriveUserCloseFullScreen = () => {
    setIsDriveUserOpenFullScreen(false);
  }

  return (
    <>
      {driveUser && (
        <div className='d-flex justify-content-end align-items-end' style={{ position: "relative", display: "inline-block", marginBottom: '-20%', marginRight: '12%' }}>
          <img className="d-block rounded-circle w-25"
            src={driveUser.URL} 
            alt="Imagem do Paciente"
            onClick={() => setIsDriveUserOpenFullScreen(true)} // Correção aqui
            style={{
              maxHeight: '50px',
              minHeight: '50px',
              maxWidth: '50px',
              zIndex: 1000,
              minWidth: '50px',
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)"
            }} />
          <i className="fa fa-trash holder icone-branco position-absolute" onClick={clearDriveUser} style={{ zIndex: '1000', bottom: '0', transform: 'translate(-260%, 78%)', scale: '0.5' }}></i>
        </div>
      )}
      <div className='text-center col-md-12 justify-content-center' style={{ position: "relative", display: "inline-block" }}>
        <img className="d-block rounded-circle w-50 mx-auto mt-2 image-fluid" style={{
          maxHeight: '200px',
          minHeight: '200px',
          maxWidth: '200px',
          minWidth: '200px',
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)"
        }} src={imageUrl}  alt="Imagem do Paciente" onClick={handleOpenModal} />
        <i className="fa fa-camera holder icone-branco position-absolute h-25" onClick={handleOpenModal} style={{
          zIndex: '1', top: '85%', left: '50%', transform: 'translate(-50%, -50%)',
        }}></i>
        <div className='d-flex justify-content-end align-items-end' style={{ position: "relative", display: "inline-block", marginTop: '-20%', marginRight: '2%' }}>
          <img className="d-block rounded-circle w-25"
            src={imageLogoAtiva} 
            alt="Imagem do Paciente"
            onClick={logoAtivaOpenFullScreen}
            style={{
              maxHeight: '50px',
              minHeight: '50px',
              maxWidth: '50px',
              minWidth: '50px',
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)"
            }} />
          <i className="fa fa-bars holder icone-branco position-absolute" onClick={logoAtivaOpenFullScreen} style={{ zIndex: '1', bottom: '0', transform: 'translate(-260%, 78%)', scale: '0.5' }}></i>
        </div>
      </div>
      {showModal && (
        <ModalSimples
          showModal={showModal}
          handleClose={handleCloseModal}
        >
          <FotoFace onPhotoUpdated={handlePhotoUpdated} />
        </ModalSimples>
      )}
      {isVisiblelogoAtivaOpenFullScreen && (
        <ModalSimples showModal={isVisiblelogoAtivaOpenFullScreen} handleClose={logoAtivaCloseFullScreen}>
          <ControleProfissional />
        </ModalSimples>
      )}
      {isDriveUserOpenFullScreen && (
        <ModalSimples showModal={isDriveUserOpenFullScreen} handleClose={isDriveUserCloseFullScreen}>
          <TitleL2>Atendendo</TitleL2>
          {driveUser.name}
        </ModalSimples>
      )}
    </>
  );
};

export default ImagemPerfilUsuario;
